<template>
   <div >
            <div v-for="(item, index) in items" :key="index"    >
                <v-card :elevation="0"   class="pages" >
                    <v-card-text  class="pa-0">
                        <div >
                           
                            <v-row class="text-center" >
                                <v-col cols="12" >
                                    <span v-if="item.sku!=null && item.sku!=''" style="font-size: 30px;"> SKU : {{ item.sku }}  </span>
                                </v-col>
                            </v-row>
                            <v-row class="text-center " > 
                                <v-col cols="12"  >
                           <barcode v-bind:value="item.barcode" height="50" width="2"   fontSize= "20"  marginTop="10"  marginBottom="1"  />
                                </v-col>
                            </v-row>
                            <v-row class="text-left"> 
                                <v-col cols="4" class="mb-1" >
                                    <span> ชื่อสินค้า :</span>
                                </v-col>
                                <v-col cols="8"  class="mb-1" >
                                    <span> {{item.name}} </span>
                                </v-col>
                            </v-row>
                            
                        
                            <v-row>
                                <v-col cols="4"  class="mb-1">
                                    <span> รหัสสินค้า :</span>
                                </v-col>
                                <v-col cols="8"  class="mb-1">
                                    <span> {{item.productCode}} </span>
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-col cols="4"  class="mb-1">
                                    <span> ลักษณะ :</span>
                                </v-col>
                                <v-col cols="8" class="mb-1" >
                                    <span> {{item.prop1Description}} </span>
                                </v-col>
                            </v-row>
                           
                            <v-row>
                                <v-col cols="4"  class="mb-1">
                                    <span>RackNo : </span>
                                </v-col>
                                <v-col cols="8"  class="mb-1" >
                                    <span> {{item.rackNo}} </span>
                                </v-col>
                            </v-row>
                             <v-row v-if="item.stockShopItemId">
                                <v-col cols="4" class="mb-1" >
                                    <span>Lot Name : </span>
                                </v-col>
                                <v-col cols="8"  class="mb-1" >
                                    <span> {{item.lotName}} </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4"  class="mb-1">
                                    <span>วันที่ผลิต : </span>
                                </v-col>
                                <v-col cols="8"  class="mb-1" >
                                    <span> {{item.mfgDate}} </span>
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-col cols="4"   class="mb-1">
                                    <p>วันหมดอายุ :</p>
                                </v-col>
                                <v-col cols="8" >
                                    <span> {{item.expirationDate}} </span>
                                </v-col>
                            </v-row>
                           
                              <v-row justify="center" v-if="item.stockShopItemId" > 
                                 
                                <v-col cols="8" >
                           <barcode :value='"lot"+item.stockShopItemId' height="40" width="2"   fontSize= "20"  marginTop="1"  marginBottom="1" :text='"LotNo : "+item.stockShopItemId'  />
                                </v-col>
                               
                          
                            </v-row>
                     
                           
                          
                          
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
</template>

<script>
window.onafterprint = function(e){
  close();
        
        console.log('Print Dialog Closed..');
    };
    window.addEventListener('afterprint', (event) => {
      close();
  console.log('After print');
});
import VueBarcode from 'vue-barcode';

export default {
components: {
            // Loading,
            'barcode': VueBarcode
            },
data: () => ({
     loading: false,
      
     items: 
      [{
        sku: 'S000003-4',
        barcode: 'PA AB-02-02-01',
        name:"โมเดลรถ CHEVROLET",
        productCode:"C00003",
        prod1:"สีฟ้า",
        rackNo:"A1",
        maneDate:"17/02/2022",
        ExeDate:"20/10/2023",
      },
      {
        sku: '12345',
        barcode: '8850051010921',
        name:"โมเดลรถ CHEVROLETสีฟ้aspiodjasdmaspkdสีฟ้aspiodjasdmaspkdสีฟ้aspiodjasdmaspkdสีฟ้aspiodjasdmaspkdสีฟ้aspiodjasdmaspkd",
        productCode:"C00003",
        prod1:"สีฟ้aspiodjasdmaspkd[pkaspod[pas;d[aoskdsap;kd[psa[pod[psa[das[pokd[psakd[aspkd[pk[า",
        rackNo:"A1",
        maneDate:"17/02/2022",
        ExeDate:"20/10/2023",
      }]
}),
async created() {

//   let query = decodeURIComponent(window.location.search.substring(1));
// let data = JSON.parse(query);


      this.items = JSON.parse(sessionStorage.getItem('selectedStockBody'))

     this.item = await this.$route.query.productMasterItemId
     //  console.log(await decodeURI(this.item))
     console.log(this.items,"productMasterItemId")

      print()
    },

}
</script>

<style scoped>
>>>.vue-barcode-element{
      width: 100%  !important;
    }
  
b{
    color: black;

}
 .cut-text{
text-decoration: none;
text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */ 
display: block; overflow: hidden; 
white-space: nowrap; 
width: 100%; /* กำหนดความกว้าง */ 
}
 .line-clamp{
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
    }
  @media print {
       .pages {
        size: 10cm 10cm;
        width: 10cm;
        height: 10cm;
        margin: 0;
        align-items: center;
        margin-top: 4px !important;
        margin-left: 4px  !important;
        margin-right: 4px  !important;
        padding-left: 4px !important;
        align-items: center;
        display: flex;
        page-break-after: always;
      }

    @page {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 0  !important;
        margin-right: 0 !important;
         }

  /* body * { 
  visibility: hidden; 
  } 
  #targetDiv, #targetDiv * { 
  visibility: visible; border: none; 
  } */
   
      
    }
    @media screen {

        .pages{
        width: 10cm;
        height: 10cm;
        margin: 0;
        margin-top: 4px !important;
        margin-left: 4px  !important;
        margin-right: 4px  !important;
        padding-left: 4px !important;
        align-items: center;
        display: flex;
      }
    }
</style>